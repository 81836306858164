.container{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin:0;
}

.prompt{
  position: fixed;
  left:10vw;
  top:40vh;
  width: 80vw;
  height: 10vh;
  background-color: white;
  border-radius: 15px;
  text-align: center;
  display: none;
  font-size: 2.3vh;
}

#agreement{
  position: fixed;
  left:5vw;
  top:5vh;
  width: 90vw;
  height: 90vh;
  background-color: white;
  border-radius: 15px;
  text-align: center;
  display: none;
  font-size: 2.3vh;
}

#agreement span{
  position: absolute;
  right:30px;
  top:20px;
}

@media (min-aspect-ratio: 9/16.5){
  .container{
    width: calc(100vh * 9 / 16);
    height: 100vh;
    margin: auto;
  }
}

@media (min-aspect-ratio: 9/17){
  #lineChart{
    margin: 5vw 7vw 5vw 7vw !important;
  }

  .card .post-outer{
    margin: 0 30px 0 0 !important;
  }

  #myCanvas{
    margin-top: 1vh !important;
    width: 85vw !important;
    height: calc(85vw*1.7) !important;
    margin-left: 7.5% !important;
  }
}

p{
  margin-top: 0;
  padding: 2vh 30px 2vh 30px;
}

p.sub45{
  padding: 1vh 30px 0.8vh 30px;
}

p.sub7{
  padding: 1vh 30px 1vh 30px;
  margin-bottom: 8px;
}

p.sub8{
  padding: 1vh 5px 0.6vh 0px;
  margin-bottom: 0;
  margin-left:0px;
}

p.smallBottomPadding{
  margin-bottom: 0;
  padding-bottom: 0;
}

.header{
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 13px;
  font-weight: normal;
}

.footer{
  position: absolute;
  text-align: left;
  left: 30px;
  bottom: 20px;
  font-size: 13px;
  font-weight: normal;
}

.bold{
  font-weight: bold;
}

.pages{
  box-sizing: border-box;
  font-size: 2.8vh;
}

#sliderCards{
  margin-left: 0;
  padding-left: 20px;
  padding-right: 2vw;
  box-sizing: border-box;
  font-size: 2.8vh;
  display: flex;
  width: 300%;
}

.card{

  position: relative;
  width: 83.5vw;
  height: 29vh;
  transition: all 0.7s;
  margin-left: 10px;
  margin-right: 5px;
  padding: 0;
}

.smallMargin{
  margin: 10px, 5px, 10px, 5px;
}

.page{
  position: relative;
  width: 100%;
  height: 100vh;
  transition: all 0.7s;
}

.arrow{
  position: absolute;
  width: 10%;
  height: auto;
  bottom: 15px;
  left: 45%;
  opacity: 40%;
  animation: floating 2s ease-in-out infinite;
}

.one{
  background-image: url("https://i.boatonland.com/report2022/cover_page.jpg");
  background-size: cover;
  text-align: center;
}

.activated{
  color: #000000 !important;
  /* animation: button_floating 2s ease-in-out infinite; */
}

.downloadBtn{
  background-color: rgb(252, 158, 25) !important;
}

.btn{
  font-size: 25px;
  position: absolute; 
  border: none;
  height: 60px;
  width: 80%;
  left: 10%;
  bottom: 8.3vh;
  color: rgba(216,216,216,0.6);
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  /* box-shadow: 2px 4px 5px 0; */
  border-radius: 15px;
  font-family: 'PuHui';
  font-weight: 75;
}

.privacy{
  position: absolute;
  bottom: 5vh;
  text-align: center;
  font-size: 1.5vh;
  display: block;
  width: 100%;
  color:rgb(92, 94, 89)
}

#permit{
  transform : scale(0.7);
}

.privacy a{
  color:rgb(92, 94, 89)
}


.icon{ 
  display: block;
  width: 30%;
  height: auto;
  margin-left: 35%;
  margin-top: 0;
  padding-top: 10vh;
  padding-bottom: 2vh;
  object-fit: cover;
  border-radius: 50%;
}

.two{
  /* position: relative; */
  font-weight: normal;
  background-image: url("https://i.boatonland.com/report2022/page_two_bg.jpg");
  background-size: cover;
  color: #ffffff;
}

.floating2{
  width: 40%;
  height: auto;
  position: absolute;
  right: -5px;
  top: -10px;
  transition: all 1s;
}

#lineChart {
  padding: 2px 2px 0px 2px;
  margin: 5vw;
  background-color:rgba(216,216,216);
  border-radius: 15px;
}

.three{
  font-weight: normal;
  background-image: url("https://i.boatonland.com/report2022/page_three_bg.jpg");
  background-size: cover;
  background-position-y: bottom;
  color: #ffffff;
}

.floating3{
  width: 40%;
  height: auto;
  position: absolute;
  right: -100px;
  top: -10px;
  transition: all 1.5s;
}

.four{
  text-align: right;
  font-weight: normal;
  background-image: url("https://i.boatonland.com/report2022/page_four_bg.jpg");
  background-size: cover;
  background-position-y: bottom;
}

.floating4{
  width: 40%;
  height: auto;
  position: absolute;
  left: 10px;
  bottom: 55px;
  transition: all 1s;
}

#keywords{
  position: relative;
  height: 35vh;
  margin: 0;
  padding: 0vh 40px 1vh 40px;

}

#word1{
  font-weight: bold;
  position: absolute;
  font-size: 6vh;
  top: 27%;
  left: 11%;
}

#word2{
  font-weight: bold;
  position: absolute;
  font-size: 5vh;
  top: 7%;
  right: 35%;
}

#word3{
  font-weight: 75;
  position: absolute;
  font-size: 4vh;
  top: 48%;
  right: 10%;
}

#word4{
  font-weight: 75;
  position: absolute;
  font-size: 3.2vh;
  top: 65%;
  left: 27%;
}
#word5{
  font-weight: normal;
  position: absolute;
  font-size: 2.9vh;
  top: 75%;
  right: 30%;
}

.five{
  background-image: url("https://i.boatonland.com/report2022/page_four_bg.jpg");
  background-size: cover;
  background-position-y: center;
}


#myCanvas{
  box-sizing: border-box;
  position: relative;
  margin-top: 8vh;
  margin-left: 5%;
  margin-bottom: 0;
  padding: 0;
  width: 90vw;
  height: calc(90vw*1.7);
  /* border:5px solid #000000; */
}

#showName{
  font-family: 'PuHui';
  font-size: 15px;
  color: #FFFFFF;
  margin-left: 15vw;
  margin-top: 0;
  padding-top: 0px;
}

#showName input{
  transform: scale(0.8);
}

.newly-added {
  animation: flyin 1.2s ease forwards;
}

.fadeOut {
  animation: fadeOut 1.2s ease forwards;
}

h1{
  margin: 0;
}


@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, -15px); }
  100%   { transform: translate(0, -0px); }   
}

@keyframes flyin {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

/* @keyframes button_floating {
  0% { transform: translate(0,  -0px);
        box-shadow: 2px 4px 5px 0; }
  50%  { transform: translate(-2, -4px); 
        box-shadow: 0px 0px 0 0;}
  100%   { transform: translate(0, 0px);
         box-shadow: 2px 4px 5px 0; }   
} */

/* styling post */

.post-outer{
  --bg-2: #fff;
  --divider:#e8e8e8;
  color: #000000;
  font-weight: normal;
  margin: 4vh 30px 20px 30px;
  border-radius:10px;
  font-size: 2vh;
  background-color: var(--bg-2);
  box-shadow: 0px 0px 10px rgba(0,0,0,0.16);
  overflow: hidden;
}
.post-outer:active {
  opacity: 0.7;
}
.post-header {
  position: relative;
  display: flex;
  /* padding:2.5vw 5vw 0vw 5vw; */
  padding: 10px 20px 0 20px;
  justify-content: space-between;
}
.post-header-title {
  display: flex;
  align-self: center;
}
.post-serial {
  font-weight: 65;
  font-size: 2vh;
  align-self: center;
}
.post-avatar-outer {
  display: inline;
  margin: 2.5px 10px 0 10px;
}
.post-avatar {
  width: 22.5px;
  height: 22.5px;
}
.post-topic {
  color: #8C8C8C;
  align-self: center;
  padding: 2.5px 7.5px;
  border-radius: 10px;
  background: var(--divider);
  margin-left: 0.1em;
}
.post-topic-text {
  font-size: 2vh;
}
.post-follower-comment-outer {
  display: flex;
  position: absolute;
  right: 1.5em;
}
.post-follower-icon {
  width: 2vh;
  height: 2vh;
  margin-top: 0.5em;
}
.post-follower-num{
  color: #8C8C8C;
  font-weight: 65;
  font-size: 2vh;
  align-self: center;
}
.post-divider{
  height: 1px;
  margin: 7.5px 10px 10px 10px;
  background-color: var(--divider);
}
.post-content {
  text-align:left;
  padding: 5px 20px 15px 20px;
  white-space: pre-wrap;
  display: flex;
  justify-content: space-between;
}
.post-content-outer {
  display: flex;
}
.post-content-text {
  font-size: 1.7vh;
  word-break: break-word;
  width: 100%;
}
.post-loadmore {
  font-size: 11px;
  color: #8C8C8C;
  margin-top: 15px;
  float: right
}
.post-image-outer {
  width: 19%;
  height: 100px;
  position: relative;
  align-self: center;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 7px;
}
.post-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

