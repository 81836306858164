@font-face {
  font-family: 'PuHui';
  src: local('PuHui'),
  url('https://i.boatonland.com/report2022/fonts/AlibabaPuHuiTi-2-55-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'PuHui';
  src: local('PuHui'),
  url('https://i.boatonland.com/report2022/fonts/AlibabaPuHuiTi-2-115-Black.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'PuHui';
  src: local('PuHui'), 
        url('https://i.boatonland.com/report2022/fonts/AlibabaPuHuiTi-2-75-SemiBold.ttf') format('truetype');
  font-weight: 75;
}


body {
  margin: 0;
  font-family: 'PuHui','PingFang HK',"PingFang", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
